import css from "styled-jsx/css";
import { fontFamily, fontWeight, fontSize } from "@/app/lib/styles";

export const containerStyles = css.global`
  html {
    scroll-behavior: smooth;
    // overflow-x: hidden;
    // max-width: 100vw;
  }
  body {
    max-width: 100vw;
    min-height: 100vh;
    background-color: white !important;
    font-family: "${fontFamily.primary}" !important;
    font-weight: ${fontWeight.medium} !important;
    line-height: initial !important;
    letter-spacing: initial !important;
    font-size: ${fontSize.default} !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }

  textarea,
  input {
    font-family: "${fontFamily.primary}" !important;
  }

  p,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  #__next,
  main {
    min-height: 100vh;
  }

  .main--content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

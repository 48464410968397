import axios from "axios";
import json from "@/app/lib/utils/api.json";

const restApi = axios.create({
  baseURL: "undefined" == typeof window ? json.api : json.client,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default restApi;

"use client";

import React, {
  useState,
  createContext,
  useEffect,
  Dispatch,
  useCallback,
} from "react";
import { route } from "@/app/lib/utils/routes";
import { usePathname, useRouter } from "next/navigation";
import { GetMe, GetMeResponse } from "@/app/lib/network/user/GetMe";

type Props = {
  children: React.ReactNode;
  setLogged?(value: boolean): void;
  setMenuHidden?(value: boolean): void;
  setAnchors?(value: boolean): void;
};

export const AppContext = createContext<{
  userMeData?: GetMeResponse;
  fetchUserMeData: () => Promise<void>;
  logged?: boolean;
  isLoading: boolean;
  menuHidden: boolean;
  anchors: { name: string; anchor: string }[];
  setLogged: Dispatch<boolean>;
  setMenuHidden: Dispatch<boolean>;
  setAnchors: Dispatch<{ name: string; anchor: string }[]>;
}>({
  userMeData: undefined,
  fetchUserMeData: () => Promise.resolve(),
  logged: undefined,
  isLoading: true,
  menuHidden: true,
  anchors: [],
  setLogged: () => Promise.resolve(),
  setMenuHidden: () => Promise.resolve(),
  setAnchors: () => Promise.resolve(),
});

export const AppContextProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [userMeData, setUserMeData] = useState<GetMeResponse | undefined>(
    undefined,
  );
  const [logged, setLogged] = useState<boolean | undefined>(undefined);
  const [menuHidden, setMenuHidden] = useState<boolean>(true);
  const [anchors, setAnchors] = useState<{ name: string; anchor: string }[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserMeData = useCallback(
    () =>
      GetMe()
        .then((res) => {
          setUserMeData(res.data);
          setLogged(true);
        })
        .catch((err) => {
          setLogged(false);
          if (401 !== err.response.status) {
            throw err;
          }
        })
        .finally(() => {
          setIsLoading(false);
        }),
    [],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (undefined === logged) {
        fetchUserMeData();
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [logged, fetchUserMeData]);

  useEffect(() => {
    if (
      true === logged &&
      undefined !== userMeData &&
      false === userMeData.validOnboarding &&
      pathname !== route.onboarding
    ) {
      router.push(route.onboarding);
    }
  }, [router, pathname, logged, userMeData]);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        userMeData,
        fetchUserMeData,
        logged,
        setLogged,
        menuHidden,
        setMenuHidden,
        anchors,
        setAnchors,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import { AxiosPromise } from "axios";
import moment, { Moment } from "moment-timezone";
import restApi from "@/app/lib/services/restApi";

export enum Type {
  NONE = "NONE",
  NOT_EXPERIENCED = "NOT_EXPERIENCED",
  EXPERIENCED = "EXPERIENCED",
}

export enum UserPersonalType {
  BASIC = "BASIC",
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
}

interface IdentityDocument {
  type: IdentityDocumentType;
  identifier: string;
  issuedInCountry: string;
  validUntil: Moment;
  validIndefinitely: boolean;
  countryOfResidence: string;
}

enum IdentityDocumentType {
  ID = "ID",
  PASSPORT = "PASSPORT",
}

export interface GetMeResponse {
  id: string;
  firstName: string;
  familyName: string;
  email: string;
  type: Type;
  mainRegulationAccepted: boolean;
  privacyPolicyRegulationAccepted: boolean;
  userPersonalType: UserPersonalType;
  phoneNumber: string;
  birthDay?: Moment;
  personalIdentityNumber: string;
  vatNumber: string;
  companyName: string;
  regon: string;
  isEmailConfirmed: boolean;
  validOnboarding: boolean;
  onboarding: {
    passedApplication: boolean;
    passedKnowledgeTest: boolean;
  };
  identityDocument: IdentityDocument;
  address: {
    streetName: string;
    streetNumber: string;
    apartmentNumber: string;
    postalCode: string;
    city: string;
    country: string;
  };
  companyRepresentativePersons: {
    firstName: string;
    familyName: string;
    position: string;
    birthDay: Moment;
    personalIdentityNumber: string;
    identityDocument: IdentityDocument;
  }[];
}

export const GetMe = (): AxiosPromise<GetMeResponse> =>
  restApi.get("/api/v1/users/me").then((res) => {
    res.data.birthDay = res.data.birthDay ? moment(res.data.birthDay) : null;
    res.data.identityDocument.validUntil = res.data.identityDocument.validUntil
      ? moment(res.data.identityDocument.validUntil)
      : null;
    for (let i = 0; i < res.data.companyRepresentativePersons.length; i++) {
      res.data.companyRepresentativePersons[i].birthDay = res.data
        .companyRepresentativePersons[i].birthDay
        ? moment(res.data.companyRepresentativePersons[i].birthDay)
        : null;
      res.data.companyRepresentativePersons[i].identityDocument.validUntil = res
        .data.companyRepresentativePersons[i].identityDocument.validUntil
        ? moment(
            res.data.companyRepresentativePersons[i].identityDocument
              .validUntil,
          )
        : null;
    }

    return res;
  });

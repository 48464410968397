import css from "styled-jsx/css";
import { Breakpoint, color, fontWeight } from "@/app/lib/styles";

export const CookieConstentStyles = css.global`
  .cookies {
    background: ${color.white};
    text-align: center;
    padding: 25px;
    max-width: 700px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .cookies__div--div-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.7);
    padding: 0 27px;
    z-index: 99999;
  }

  .cookies__buttons--button {
    color: ${color.white};
    text-transform: uppercase;
    margin: 0;
    font-size: 0.75rem;
    font-weight: ${fontWeight.bold};
    border: none;
    cursor: pointer;
    padding: 14px 28px;
    line-height: 1rem;
    text-align: center;
    border-radius: 24px;
    background: linear-gradient(321.93deg, #ff359c 0%, #4200ff 100%);
    box-shadow: 0 2px 24px 0 rgba(66, 0, 255, 0.25);
    text-decoration: none;
    margin-top: 16px;
  }

  .cookies__buttons--button:hover {
    background: linear-gradient(321.93deg, #c90066 0%, #2f05a8 100%);
  }

  .cookies__typography--p-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: ${color.black};
    margin-top: 20px;
  }

  .cookies__typography--p-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 0.4rem;
    line-height: 0.65rem;
    text-align: left;
    color: ${color.black};
    margin-top: 16px;
  }

  .cookies__image--div-wrapper {
  }

  .cookies__image--div-wrapper svg {
  }

  @media (min-width: ${Breakpoint.DESKTOP_BIG}) {
    .cookies__typography--p-header {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .cookies__typography--p-content {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

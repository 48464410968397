"use client";

import React, { useEffect, useState, useContext } from "react";
import Link from "next/link";
import { AppContext } from "@/app/lib/contexts/AppContext";
import {
  GetOrders,
  GetOrdersResponseItem,
} from "@/app/lib/network/order/GetOrders";
import {
  Box,
  Button,
  IconButton,
  SnackbarContent,
  Typography,
} from "@mui/material";

import { Symbol } from "@/app/lib/theme/Symbol/Symbol";

export const Badge: React.FunctionComponent = () => {
  const { logged } = useContext(AppContext);
  const [orders, setOrders] = useState<GetOrdersResponseItem[]>();
  const [notSigned, setNotSigned] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isOnInvestPage, setIsOnInvestPage] = useState(0);

  const [showSignedAlert, setShowSignedAlert] = useState<boolean>(false);
  const [showConfirmedAlert, setShowConfirmedAlert] = useState<boolean>(true);

  useEffect(() => {
    if (true === logged) {
      GetOrders().then((res) => {
        setOrders(res.data);
      });
    }
  }, [logged]);

  useEffect(() => {
    if (orders) {
      const hasUnsigned = orders.filter((x) => x.status === "NEW").length > 0;
      const hasConfirmed =
        orders.filter(
          (x) =>
            ["CONFIRMED", "PAYMENT_IN_PROGRESS"].includes(x.status) &&
            new Date(x.canPayAfter).getTime() < Date.now(),
        ).length > 0;

      setNotSigned(hasUnsigned);
      setConfirmed(hasConfirmed);

      setShowSignedAlert(hasUnsigned);
      setShowConfirmedAlert(hasConfirmed);

      setIsOnInvestPage(
        document.getElementsByClassName("invest__footer").length,
      );
    }
  }, [orders]);

  useEffect(() => {
    const element = document.getElementsByClassName("invest__footer")[0];
    if (element && (notSigned || confirmed)) {
      element.classList.add("invest__fixed");
    }
  }, [confirmed, isOnInvestPage, notSigned]);

  const handleCloseNotSigned = () => {
    setShowSignedAlert(false);
  };

  const handleCloseConfirmed = () => {
    setShowConfirmedAlert(false);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: { xs: 64, sm: 72 },
        left: "50%",
        maxWidth: "650px",
        width: { xs: "90%", md: "100%" },
        transform: "translateX(-50%)",
        zIndex: 99999,
      }}
    >
      {true === logged && (!!notSigned || !!confirmed) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {showConfirmedAlert && (
            <Box>
              <SnackbarContent
                message={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Symbol name="check_circle" />
                    <Typography variant="body3b">
                      Możesz już opłacić zapis na akcje.
                    </Typography>
                  </Box>
                }
                action={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.56)",
                      }}
                    >
                      <Link
                        href="/profil?menu=2"
                        style={{
                          marginLeft: "auto",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Opłać zapis
                      </Link>
                    </Button>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseConfirmed}
                    >
                      <Symbol name="close" color="black" />
                    </IconButton>
                  </Box>
                }
                sx={{
                  background: "rgba(82, 191, 108, 1)",
                }}
              />
            </Box>
          )}
          {showSignedAlert && (
            <Box>
              <SnackbarContent
                message={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Symbol name="check_circle" />
                    <Typography variant="body3b">
                      Przy jednej z twoich inwestycji brakuje Twojego podpisu.
                    </Typography>
                  </Box>
                }
                action={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.56)",
                      }}
                    >
                      <Link
                        href="/profil?menu=2"
                        style={{
                          marginLeft: "auto",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Złóż podpis
                      </Link>
                    </Button>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseNotSigned}
                    >
                      <Symbol name="close" color="black" />
                    </IconButton>
                  </Box>
                }
                sx={{
                  background: "rgba(255, 79, 0, 1)",
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

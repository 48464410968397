export const color = {
  primary: "#4329ff",
  primary2: "#252525",

  secondary: "#d329b2",
  secondaryMedium: "#cdc0f8",
  secondarySoft: "#dfdcfc",
  secondaryLight: "#ece7fd",
  secondaryDark: "#838cf2",

  lightPink: "#EEE9FE",
  grey: "#e7e2f4",
  denaturat: "#4200FF",
  greySoft: "#f7f5fe",
  greyPerks: "#EEEDF0",
  lighterGrey: "#eeeeee",
  lightGrey: "#666666",
  white: "#ffffff",
  black: "#000000",
  pink: "#DB2CA5",
  footer: "#19015C",
  red: "#F76262",
  black2: "#252525",
  vandalBlack: "#333",

  success: "#15C499",
  successLight: "#ebfaf6",
  failure: "#FF4F00",
  failureLight: "#fcefe9",
  progress: "#fe9b00",
  info: "#00C2FF",
  hr: "#dddddd",
};

import { AxiosPromise } from "axios";
import { OrderStatus } from "@/app/lib/domain/order/OrderStatus";
import restApi from "@/app/lib/services/restApi";
import { Type } from "../user/GetMe";
import { IssueType } from "../issue/enum";

export interface GetOrdersResponseItem {
  id: string;
  issue: {
    issueDescription: {
      name: string;
      slug: string;
      logoPath: string;
    };
    type: IssueType;
  };
  status: OrderStatus;
  investorSnapshot: {
    type: Type;
  };
  shares: {
    id: string;
    amount: number;
    seriesOfShares: {
      price: number;
      name: string;
    };
    document?: {
      id: string;
    };
    signedDocument?: {
      id: string;
    };
    signedDocumentByCompany?: {
      id: string;
    };
  }[];
  totalAmount: number;
  canPayAfter: Date;
  createdAt: Date;
}

export const GetOrders = (): AxiosPromise<GetOrdersResponseItem[]> =>
  restApi.get("/api/v1/orders").then((res) => {
    for (let i = 0; i < res.data.length; i++) {
      res.data[i].canPayAfter = new Date(res.data[i].canPayAfter);
      res.data[i].createdAt = new Date(res.data[i].createdAt);
    }

    return res;
  });

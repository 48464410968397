export const gradient = {
  primary: "linear-gradient(321.93deg, #ff359c 0%, #4200ff 100%)",
  primaryDark: "linear-gradient(321.93deg, #C90066 0%, #2F05A8 100%)",
  background:
    "linear-gradient( 270deg, #969696 0%,rgba(66, 0, 255, 0.68) 100%)",
  progressBar1: "linear-gradient(270deg, #EC2525 0%, #4C23C2 100%)",
  progressBar2: "linear-gradient(270deg, #FF4F00 0%, #FFB300 100%)",
  progressBar3: "linear-gradient(270deg, #16D4FF 0%, #838CF3 100%)",
  progressBar4: "linear-gradient(270deg, #35FF8A 0%, #35A89C 100%)",
  primaryCircle: "radial-gradient(circle at top left, #4200ff, #ff359c);",
};

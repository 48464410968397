import Link from "next/link";
import ReactCookieConsent from "react-cookie-consent";
import { route } from "@/app/lib/utils/routes";
import { CookieConstentStyles } from "./CookieConsent.styles";
import CookieConsentIllustration from "@/app/lib/static/svg/CookieConsent.svg";

export const CookieConsent: React.FunctionComponent = () => {
  return (
    <>
      <ReactCookieConsent
        disableStyles={true}
        overlay={true}
        overlayClasses="cookies__div--div-overlay"
        buttonClasses="cookies__buttons--button"
        containerClasses="cookies"
        buttonText="AKCEPTUJĘ I PRZECHODZĘ DO SERWISU"
      >
        <div className={"cookies__image--div-wrapper"}>
          <CookieConsentIllustration />
        </div>
        <p className={"cookies__typography--p-header"}>
          Wszyscy lubimy ciastka, ale my przede wszystkim szanujemy Twoją
          prywatność.
        </p>
        <p className={"cookies__typography--p-content"}>
          Uprzejmie informujemy, że ten Serwis korzysta z plików cookie -
          włączając w to zewnętrzne pliki cookie - oraz innych technologii,
          które są niezbędne do prawidłowego działania Serwisu. Korzystamy z
          plików cookie do gromadzenia informacji o tym, w jaki sposób
          Użytkownicy korzystają z Serwisu, co pozwala na bieżąco ulepszać
          działanie witryny oraz zapewnić optymalne warunki korzystania z
          Serwisu. Zbierane dane przetwarzane są w celach technicznych,
          analitycznych, statystycznych oraz marketingowych pozwalających na
          poprawę jakości funkcjonowania serwisu i jakości świadczonych usług
          oraz dostosowywania wyświetlanych treści reklamowych. Aby uzyskać
          więcej informacji na temat gromadzonych danych i sposobu ich
          udostępniania partnerom Serwisu, należy zapoznać się z{" "}
          <Link href={route.privacyPolicy}>Polityką prywatności</Link>.
        </p>
      </ReactCookieConsent>
      <style jsx global>
        {CookieConstentStyles}
      </style>
    </>
  );
};

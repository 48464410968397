export const fontFamily = {
  primary: "Montserrat",
};

export const fontSize = {
  extraSmall: "10px",
  small: "12px",
  default: "14px",
  big: "16px",
  bigger: "18px",
  subtitle: "24px",
  smallTitle: "28px",
  title: "34px",
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  black: 900,
};

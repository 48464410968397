import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { MenuList, Paper, Typography } from "@mui/material";
import { useRouter } from "next/navigation";

interface MenuItemsMapProps {
  title: string;
  content?: string;
  type: boolean | string;
  link?: string;
}

interface MenuItemsProps {
  [key: string]: MenuItemsMapProps;
}

const menuItems: MenuItemsProps = {
  forInvestor: {
    title: "Dla inwestora",
    type: false,
  },
  lookingForInvestor: {
    title: "Szukam inwestora",
    type: false,
  },
  worthKnowing: {
    title: "Warto wiedzieć",
    type: false,
  },
  contact: {
    title: "Kontakt",
    type: "anchor",
    link: "kontakt",
  },
  aftermarket: {
    title: "Tablica ofert",
    content: "Kup lub sprzedaj akcje wyemitowane na Emiteo",
    type: "link",
    link: "/tablica-ofert/",
  },
  projects: {
    title: "Prekampanie i emisje",
    content: "Zainwestuj w spółki dostępne \n na Emiteo",
    type: "anchor",
    link: "issues",
  },
  realEstate: {
    title: "Branża nieruchomości",
    content: "Znajdź finansowanie dla projektu nieruchomościowego",
    type: "link",
    link: "/nieruchomosci/",
  },
  otherIndustries: {
    title: "Inne branże",
    content: "Pozyskaj finansowanie na rozwój swojego projektu",
    type: "link",
    link: "/szukam-inwestora/",
  },
  blog: {
    title: "Blog Emiteo",
    content: "Przeczytaj o polskim crowdfundingu i nie tylko",
    type: "link",
    link: "/blog/",
  },
  knowledgeBase: {
    title: "Baza wiedzy",
    content: "Dowiedz, się jak sprawnie korzystać z naszej platformy",
    type: "external",
    link: "https://emiteo.helpscoutdocs.com/",
  },
  ecf: {
    title: "Baza danych ECF",
    content: "Zobacz jakie wyniki osiągały emisje akcji ECF w Polsce",
    type: "link",
    link: "/polskie-crowdfundingowe-emisje-akcji/",
  },
};

export default function NavbarMenu({
  title,
  menu,
  fullWidth,
  closeDrawer,
}: {
  title: string;
  menu?: string[];
  fullWidth?: boolean;
  closeDrawer?: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const router = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (menuItem: MenuItemsMapProps) => {
    setAnchorEl(null);
    if (closeDrawer) {
      closeDrawer();
    }
    const link = menuItem?.link as string;

    switch (menuItem.type) {
      case "link":
        router.push(link);
        break;

      case "anchor":
        const element = document.getElementById(link);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        } else {
          router.push(`/#${menuItem.link}`);
        }
        break;
      case "external":
        window.open(menuItem.link, "_ blank");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) =>
          menu ? handleClick(event) : handleClose(menuItems[title]!)
        }
        endIcon={menu && (open ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
        sx={{
          color: { xs: "#252525", lg: open ? "#252525" : "#25252580" },
          fontWeight: "normal",
          justifyContent: fullWidth ? "space-between" : "initial",
          fontSize: "1rem",
        }}
        fullWidth={fullWidth}
      >
        {menuItems[title] && menuItems[title].title}
      </Button>
      {menu && (
        <>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              display: { xs: "none", lg: "block" },
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {menu &&
              menu.map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => handleClose(menuItems[item]!)}
                  sx={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Typography variant={"body2a"}>
                    {menuItems[item] && menuItems[item].title}
                  </Typography>
                  <Typography
                    variant={"body2a"}
                    component={"p"}
                    sx={{
                      color: "#25252599",
                      maxWidth: "260px",
                      textWrap: "initial",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {menuItems[item] && menuItems[item].content}
                  </Typography>
                </MenuItem>
              ))}
          </Menu>

          <Paper
            sx={{
              display: { xs: open ? "block" : "none", lg: "none" },
              width: "90%",
              boxShadow: "none",
            }}
          >
            <MenuList>
              {menu &&
                menu.map((item) => (
                  <MenuItem
                    key={item}
                    onClick={() => handleClose(menuItems[item]!)}
                    sx={{ flexDirection: "column", alignItems: "flex-start" }}
                  >
                    <Typography variant={"body2a"}>
                      {menuItems[item] && menuItems[item].title}
                    </Typography>
                    <Typography
                      variant={"body2a"}
                      component={"p"}
                      sx={{
                        color: "#25252599",
                        maxWidth: "260px",
                        textWrap: "initial",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {menuItems[item] && menuItems[item].content}
                    </Typography>
                  </MenuItem>
                ))}
            </MenuList>
          </Paper>
        </>
      )}
    </>
  );
}

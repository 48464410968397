"use client";

import React from "react";
import Image from "next/image";
import { CookieConsent } from "./parts/CookieConsent/CookieConsent";
import { Badge } from "./parts/Badge/Badge";
import { Box, Container, Typography } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { Symbol } from "@/app/lib/theme/Symbol/Symbol";

export const FooterMain: React.FunctionComponent = () => {
  return (
    <Box
      component="footer"
      sx={{ background: deepPurple[900], color: "white" }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: 10,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          gap: { xs: 5, md: 10 },
          textAlign: { xs: "center", md: "start" },
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "start" }}
          gap={{ xs: 2, md: 3 }}
          sx={{ maxWidth: { md: "705px" }, width: "100%" }}
          flex={1}
        >
          <Symbol name="import_contacts" size="27px" />
          <Typography variant="body4a">
            Inwestowanie w startupy i firmy na wczesnym etapie rozwoju wiąże się
            z wysokim ryzykiem, m.&nbsp;in.:&nbsp;braku&nbsp;płynności, braku
            dywidend, utratą części lub całości zainwestowanych środków
            i&nbsp;rozwodnieniem.&nbsp;Tego typu inwestycje powinny się odbywać
            tylko w ramach zdywersyfikowanego portfela. Ta platforma jest
            skierowana wyłącznie do inwestorów, którzy posiadają wystarczającą
            wiedzę, aby zrozumieć te ryzyka i podejmować własne decyzje
            inwestycyjne.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          gap={{ xs: 5, md: 10 }}
        >
          <Typography variant="body5a">
            Emiteo sp. z o.o. <br />
            ul. Wojska Polskiego 24-26 <br />
            75-712 Koszalin <br />
            KRS 0000820021 <br />
            NIP 669-255-57-33 <br />
            kapitał zakładowy: 117.200 PLN
          </Typography>
          <Box display="flex" flexDirection="column" gap={{ xs: 1, md: 3 }}>
            <Box>
              <Image
                width="96"
                height="25"
                alt="logo"
                src="/emiteo-white.png"
              />
            </Box>
            <Typography variant="body4a">
              © 2025 Emiteo <br />
              All rights reserved
            </Typography>
          </Box>
        </Box>
      </Container>
      <CookieConsent />
      <Badge />
    </Box>
  );
};

"use client";

import React, { useContext, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { AppContext } from "@/app/lib/contexts/AppContext";
import { route } from "@/app/lib/utils/routes";
import { Cancel } from "@/app/lib/network/token/Cancel";
import LogoSVG from "@/app/lib/static/svg/logo.svg";

import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@mui/material";

import { Menu as MenuIcon, AccountCircle, Close } from "@mui/icons-material";
import NavbarMenu from "./parts/NavbarMenu";

export const HeaderMain: React.FunctionComponent = ({}) => {
  const { logged, setLogged } = useContext(AppContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const router = useRouter();

  const userLogout = () => {
    Cancel().then(() => {
      setLogged(false);
      router.push("/");
    });
  };

  return (
    <>
      <Box sx={{ height: "65px" }}></Box>
      <AppBar>
        <Container>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ width: 130 }}>
                <Link href={"/"}>
                  <LogoSVG
                    style={{
                      overflow: "visible",
                    }}
                  />
                </Link>
              </Box>
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                gap: 1,
                alignItems: "center",
                display: { xs: "none", lg: "flex" },
              }}
            >
              <NavbarMenu
                title={"forInvestor"}
                menu={["projects", "aftermarket"]}
              />
              <NavbarMenu
                title={"lookingForInvestor"}
                menu={["realEstate", "otherIndustries"]}
              />
              <NavbarMenu
                title={"worthKnowing"}
                menu={["blog", "knowledgeBase", "ecf"]}
              />
              <NavbarMenu title={"contact"} />

              {true === logged ? (
                <Box
                  ml={4}
                  sx={{ gap: 1, display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="false"
                    LinkComponent={Link}
                    href={`${route.profile}?menu=1`}
                  >
                    <AccountCircle />
                  </IconButton>

                  <Button variant="contained" onClick={() => userLogout()}>
                    Wyloguj się
                  </Button>
                </Box>
              ) : (
                <Box ml={4} sx={{ gap: 1, display: "flex" }}>
                  <Button
                    variant="outlined"
                    LinkComponent={Link}
                    href={route.login}
                  >
                    Zaloguj
                  </Button>

                  <Button
                    variant="contained"
                    LinkComponent={Link}
                    href={route.register()}
                  >
                    Zarejestruj się
                  </Button>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", lg: "none" },
                alignItems: "center",
                gap: 1,
              }}
            >
              {true === logged && (
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  LinkComponent={Link}
                  href={`${route.profile}?menu=1`}
                >
                  <AccountCircle />
                </IconButton>
              )}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setIsDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Drawer
              anchor={"right"}
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                padding={1}
                paddingRight={2}
              >
                <IconButton onClick={() => setIsDrawerOpen(false)}>
                  <Close />
                </IconButton>
              </Box>
              <List>
                <ListItem sx={{ flexDirection: "column" }}>
                  <NavbarMenu
                    fullWidth
                    title={"forInvestor"}
                    menu={["aftermarket", "projects"]}
                    closeDrawer={() => setIsDrawerOpen(false)}
                  />
                </ListItem>

                <ListItem sx={{ flexDirection: "column" }}>
                  <NavbarMenu
                    fullWidth
                    title={"lookingForInvestor"}
                    menu={["realEstate", "otherIndustries"]}
                    closeDrawer={() => setIsDrawerOpen(false)}
                  />
                </ListItem>

                <ListItem sx={{ flexDirection: "column" }}>
                  <NavbarMenu
                    fullWidth
                    title={"worthKnowing"}
                    menu={["blog", "knowledgeBase", "ecf"]}
                    closeDrawer={() => setIsDrawerOpen(false)}
                  />
                </ListItem>

                <ListItem sx={{ flexDirection: "column" }}>
                  <NavbarMenu
                    fullWidth
                    title={"contact"}
                    closeDrawer={() => setIsDrawerOpen(false)}
                  />
                </ListItem>
              </List>

              <Divider />

              {true === logged ? (
                <List>
                  <ListItem>
                    <Button
                      variant="outlined"
                      size="large"
                      fullWidth
                      LinkComponent={Link}
                      href={`${route.profile}?menu=1`}
                    >
                      Moje konto
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      onClick={() => userLogout()}
                    >
                      Wyloguj się
                    </Button>
                  </ListItem>
                </List>
              ) : (
                <List>
                  <ListItem>
                    <Button
                      variant="outlined"
                      size="large"
                      fullWidth
                      LinkComponent={Link}
                      href={route.login}
                    >
                      Zaloguj
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      LinkComponent={Link}
                      href={route.register()}
                    >
                      Zarejestruj się
                    </Button>
                  </ListItem>
                </List>
              )}
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

"use client";

import React from "react";
import { containerStyles } from "./Container.styles";

type Props = {
  Header: React.ReactNode;
  Footer: React.ReactNode;
  children: React.ReactNode;
};

export const ContainerMain: React.FunctionComponent<Props> = ({
  Header: HeaderComponent,
  Footer: FooterComponent,
  children,
}) => {
  return (
    <>
      <div className="main--content">
        {HeaderComponent}

        {children}

        {FooterComponent}
      </div>

      <style jsx global>
        {containerStyles}
      </style>
    </>
  );
};

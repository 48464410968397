import { styled, MUIStyledCommonProps, PaletteProps } from "@mui/system";
import { SymbolName } from "./SymbolName";

interface StyledSymbolProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    MUIStyledCommonProps {
  fill?: 0 | 1;
  wght?: 300 | 400;
  grad?: 0;
  opsz?: 20 | 21 | 22 | 23 | 24;
  size?: string;
  color?: string;
}

export const StyledSymbol = styled("span", {
  shouldForwardProp: (prop: string) =>
    !["fill", "wght", "grad", "opsz", "sx"].includes(prop),
})<StyledSymbolProps>(({ fill, wght, grad, opsz, color, size, theme }) => ({
  fontVariationSettings: [
    fill ? `"FILL" ${fill}` : "",
    wght ? `"wght" ${wght}` : "",
    grad ? `"GRAD" ${grad}` : "",
    opsz ? `"OPSZ" ${opsz}` : "",
  ]
    .filter(Boolean)
    .join(","),
  ...(size ? { fontSize: `${size} !important` } : {}),
  color: ((color?: string) => {
    if (!color) {
      return color;
    }

    const paletteColorKeys = Object.keys(theme.palette);
    if (paletteColorKeys.includes(color)) {
      const paletteEntry = theme.palette[color as keyof PaletteProps];
      return paletteEntry instanceof Object && "main" in paletteEntry
        ? paletteEntry.main
        : color;
    }

    return color;
  })(color),
}));

interface SymbolProps extends StyledSymbolProps {
  name: SymbolName;
}

export const Symbol = ({ name, ...rest }: SymbolProps) => {
  return (
    <StyledSymbol
      {...rest}
      className={`material-symbols-rounded ${rest.className}`}
    >
      {name}
    </StyledSymbol>
  );
};

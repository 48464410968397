// Breakpoints
// ------------------------------------------
// * device
// * device-small
// * device-medium
// * device-big
// ------------------------------------------
export enum Breakpoint {
  MOBILE = "450px",
  TABLET = "768px",
  DESKTOP = "1024px",
  DESKTOP_MEDIUM = "1280px",
  DESKTOP_BIG = "1440px",
}

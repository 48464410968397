export const route = {
  main: "/",
  register: (returnUrl?: string) =>
    `/rejestracja${returnUrl ? `?returnUrl=${returnUrl}` : ""}`,
  login: "/zaloguj",
  profile: "/profil",
  blog: (page?: number) => `/blog${page ? `?p=${page}` : ""}`,
  category: (slug: string, page?: number) =>
    `/kategoria/${slug}${page ? `?p=${page}` : ""}`,
  post: (slug: string) => `/post/${slug}`,
  contact: "/#kontakt",
  ecf: "/polskie-crowdfundingowe-emisje-akcji",
  funding: "/szukam-inwestora",
  getFinancingThankYou: "/szukam-inwestora-dziekujemy",
  thankYouForContact: "/dziekujemy-za-kontakt",
  aftermarket: "/tablica-ofert",
  realEstate: "/nieruchomosci",
  newsletter: "/#newsletter",
  media: "#",
  press: "#",
  download: "#",
  pzInstruction:
    "https://prod-emiteo-api.s3.eu-central-1.amazonaws.com/public/files/file/fc1c00b0-44ad-489c-9bc0-32d1dbf948ac.instrukcja-podpisu-epuap.pdf",
  faq: "https://emiteo.helpscoutdocs.com/",
  termsAndConditions: "/strona/regulamin",
  privacyPolicy: "/strona/polityka-prywatnosci",
  newsletterRegualations: "/strona/regulamin-newsletter",
  newsletterRegualationsEmiteo: "/strona/regulamin-newsletter",
  considerationComplaint: "/strona/regulamin-rozpatrywania-skarg",
  informationInvestors: "/strona/dodatkowe-informacje-dla-inwestorow",
  conflictInterest: "/strona/zapobieganie-konfliktow-interesow",
  investmentRisks: "/strona/ryzyka-inwestycyjne",
  aboutUs: "/strona/o-nas",
  onboarding: "/onboarding",
  twitter: "",
  user: {
    change: "/uzytkownik/zmien-haslo",
    reset: "/uzytkownik/nowe-haslo",
    confirm: "/uzytkownik/potwierdz-konto",
  },
  error: "/blad",
  facebook: "https://www.facebook.com/Emiteo-631314354048260/",
  linkedin: "https://www.linkedin.com/company/emiteo",
  discord: "https://discord.com/invite/2p7gcZ3dJV",
  instagram: "https://www.instagram.com/emiteo.pl/",
  youtube: "https://www.youtube.com/@emiteo52",
  issue: (slug: string) => `/${slug}`,
  issueInvest: (slug: string) => `/${slug}/zainwestuj`,
  issueInvestSuccess: (slug: string, orderIds: string[]) =>
    `/${slug}/zainwestuj/sukces?${orderIds.map((orderId) => `orderId=${orderId}`).join("&")}`,
  issueLawRegulationEmail: (slug: string) => `/${slug}/klauzula-email`,
  issueLawRegulationPhone: (slug: string) => `/${slug}/klauzula-telefon`,
  lawRegulationEmail: "/klauzula-email",
  lawRegulationPhone: "/klauzula-telefon",
  lawRegulationRealEstate: "/klauzula-nieruchomosci",
};
